
import { menuController, alertController, IonContent, IonPage, IonRow, IonCol, IonGrid, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonImg, IonButton, IonButtons } from '@ionic/vue';
import { defineComponent } from '@vue/runtime-core';
import axios from 'axios';
import { Storage } from '@capacitor/storage';
export default defineComponent({
  name: 'Folder',
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonGrid, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonImg, IonButton, IonButtons
  },
  data: () => ({
    mLoginInput: '',
    field: "",
    clientData: {},
    urlParams: {},
  }),
  methods: {
    backPressed() {
      this.$router.push('/');
    },
    async submitLoginForm() {
      console.log(this.mLoginInput);
      if (this.mLoginInput) { this.loadClient(); }

    },
    setClientData(keyName, dataValue) {
      Storage.set({
        key: keyName,
        value: JSON.stringify(dataValue),
      })
        .then
      // console.log("Asignado a storage exitosamente - " + JSON.stringify(dataValue)); 
    },
    async getClientData(keyName) {
      const ret = await Storage.get({ key: keyName });
      if (ret.value) {
        this.clientData = this.isJson(ret.value);
        this.emitter.emit("g-data", ret.value);
        // console.log("Recuperado de storage - " + this.clientData); 
      }
    },
    loadClient() {
      const clientId = { id: this.mLoginInput };
      axios.post("https://vybroo.net/client", clientId)
        .then(response => {
          if (response.data.error == true || response.data.disabled == true) {
            if (response.data.error == true) console.log("El canal no existe.");
            if (response.data.disabled == true) console.log("El canal existe pero está deshabilitado.");
            this.presentAlertConfirm("Canal no encontrado", "No se ha podido encontrar el canal " + this.mLoginInput.toLowerCase() + ".")
            this.mLoginInput = "";
          } else {
            console.log("Canal encontrado");
            this.clientData = {};
            this.getClientData("clientData").then(() => {
              if (this.clientData.link != this.mLoginInput.toLowerCase()) {
                console.log(this.clientData.link);
                console.log(this.mLoginInput.toLowerCase())
                console.log("El canal es distinto, se borró el storage")
                Storage.clear().then(() => {
                  this.setClientData("clientData", response.data);
                  this.emitter.emit("set-client");
                  this.$router.replace("/tabs/tabReproductor" + this.urlParams.fullPath);
                })
              }
              else {
                console.log(this.clientData.link);
                console.log(this.mLoginInput.toLowerCase());
                Storage.remove({ key: 'clientData' }).then(() => {
                  this.setClientData("clientData", response.data);
                });
                console.log("El canal es el mismo, no se borró el storage")
                this.$router.replace("/tabs/tabReproductor" + this.urlParams.fullPath);
              }
            })

          }
          // console.log(response.data);
        })
        .catch(error => {
          //console.error("There was an error!", error);
          this.loadClientVBack(clientId, error);
        });
    },
    loadClientVBack(clientId, loadClienterror) {
       
      axios.post("/transmito/vbk_get_client/?link="+clientId.id)
        .then(response => {
          if (response.data.error == true || response.data.disabled == true) {
            if (response.data.error == true) console.log("El canal no existe.");
            if (response.data.disabled == true) console.log("El canal existe pero está deshabilitado.");
            this.presentAlertConfirm("Canal no encontrado", "No se ha podido encontrar el canal " + this.mLoginInput.toLowerCase() + ".")
            this.mLoginInput = "";
          } else {
            console.log("Canal encontrado");
            this.clientData = {};
            this.getClientData("clientData").then(() => {
              if (this.clientData.link != this.mLoginInput.toLowerCase()) {
                console.log(this.clientData.link);
                console.log(this.mLoginInput.toLowerCase())
                console.log("El canal es distinto, se borró el storage")
                Storage.clear().then(() => {
                  this.setClientData("clientData", response.data);
                  this.emitter.emit("set-client");
                  this.$router.replace("/tabs/tabReproductor" + this.urlParams.fullPath);
                })
              }
              else {
                console.log(this.clientData.link);
                console.log(this.mLoginInput.toLowerCase());
                Storage.remove({ key: 'clientData' }).then(() => {
                  this.setClientData("clientData", response.data);
                });
                console.log("El canal es el mismo, no se borró el storage")
                this.$router.replace("/tabs/tabReproductor" + this.urlParams.fullPath);
              }
            })

          }
          // console.log(response.data);
        })
        .catch(error => {
          console.error("loadClientVBack::There was an error!", error);
          console.error("loadClient::There was an error!", loadClienterror);
        });
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    makeFullPath() {
      try {
        this.urlParams.fullPath = "";
        this.urlParams.sucursalPath = "";
        this.urlParams.sucursalNamePath = "";
        this.urlParams.categoriaPath = "";
        this.urlParams.variantePath = "";
        this.urlParams.hayParametros = false;

        //SUCURSAL
        if (Number(this.urlParams.sucursal) >= 0 && this.urlParams.sucursal) {
          this.urlParams.sucursalPath = "&sucursal=" + this.urlParams.sucursal;
          console.log(this.urlParams.sucursalPath);
          this.urlParams.hayParametros = true;
        }

        //SUCURSALNAME
        if (this.urlParams.sucursalName && this.urlParams.sucursalName.length > 0) {
          this.urlParams.sucursalNamePath = "&sucursalName=" + this.urlParams.sucursalName;
          console.log(this.urlParams.sucursalNamePath);
          this.urlParams.hayParametros = true;
        }

        //CATEGORÍA MUSICAL
        if (Number(this.urlParams.categoria) >= 0 && this.urlParams.categoria) {
          this.urlParams.categoriaPath = "&categoria=" + this.urlParams.categoria;
          console.log(this.urlParams.categoriaPath);
          this.urlParams.hayParametros = true;
        }
        //VARIANTE DE CONTENIDO
        if (Number(this.urlParams.variante) >= 0 && this.urlParams.variante) {
          this.urlParams.variantePath = "&variante=" + this.urlParams.variante;
          console.log(this.urlParams.variantePath);
          this.urlParams.hayParametros = true;
        }

        if (this.urlParams.hayParametros == true) {
          this.urlParams.fullPath = "?" + this.urlParams.sucursalPath + this.urlParams.sucursalNamePath + this.urlParams.categoriaPath + this.urlParams.variantePath;
          console.log(this.urlParams.fullPath);
        } else {
          console.log("No se pasaron parámetros en el url");
        }

      } catch (e) {
        console.error("Error en la función makeFullPath de directLogin.vue - " + e);
      }
    }
  },
  mounted: function () {
    // PARÁMETROS EN EL URL
    const queryString = window.location.search;
    const urlParametros = new URLSearchParams(queryString);
    this.urlParams.sucursal = urlParametros.get('sucursal');
    this.urlParams.sucursalName = urlParametros.get('sucursalName');
    this.urlParams.categoria = urlParametros.get('categoria');
    this.urlParams.variante = urlParametros.get('variante');
    this.makeFullPath();
    menuController.close("menustudio");
    menuController.enable(false, "menustudio");
    this.mLoginInput = this.$route.params.channellink ? this.$route.params.channellink : "";
    this.loadClient();
  }
})
